<template>
  <div class="px-2 py-5">
    <h1 class="title">Nail Psoriasis Severity Index (NAPSI)</h1>

    <h1 class="title has-text-centered">UÑA DEDOS DE LAS MANOS</h1>
    <br />
    <NapsiDefaultForm
      module="hands"
      :question="questions.MANOS"
      :answers="answers.MANOS"
      :temporalData="temporal.hands"
      :queryAnswers="handQueryAnsers"
      :queryMode="queryMode"
      @formInfo="setInfo"
    />
    <hr />
    <h1 class="title has-text-centered">UÑA DEDOS DE LOS PIES</h1>
    <br />
    <NapsiDefaultForm
      module="feet"
      :question="questions.PIES"
      :answers="answers.PIES"
      :temporalData="temporal.feet"
      :queryMode="queryMode"
      :queryAnswers="feetQueryAnsers"
      @formInfo="setInfo"
    />

    <div class="block has-text-centered title mt-6" v-if="!queryMode">
      NAPSI (0 - 160): {{ score }}
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>

    <div class="block has-text-centered title mt-6" v-if="queryMode">
      NAPSI (0 - 160): {{ result }}
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button
        class="save-button"
        icon-right="check-bold"
        @click="validate"
        :disable="score === 0"
        >Guardar</b-button
      >
    </div>
    <!-- <section>
      <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
      >
        <b-step-item
          step="1"
          label="DEDOS DE LAS MANOS"
          :clickable="isStepsClickable"
          :type="handNailsComplete ? 'is-success' : 'is-info'"
        >
          
        </b-step-item>

        <b-step-item
          step="2"
          label="DEDOS DE LOS PIES"
          :clickable="isStepsClickable"
          :type="feetNailsComplete ? 'is-success' : 'is-info'"
        >
          
        </b-step-item>

        <template v-if="customNavigation" #navigation="{previous, next}">
          
          <div class="buttons is-centered mt-5">
            <b-button
              icon-left="chevron-left"
              :disabled="previous.disabled"
              @click.prevent="previous.action"
              class="prev-button"
            >
              Anterior
            </b-button>
            <b-button
              icon-right="check-bold"
              :disabled="!next.disabled && !queryMode"
              v-if="next.disabled"
              @click.prevent="validate"
              class="has-text-rigth save-button"
            >
              Guardar
            </b-button>
            <b-button
              icon-right="block-helper"
              @click.prevent="clear"
              v-if="!queryMode"
              class="has-text-rigth clear-button"
            >
              Limpiar
            </b-button>

            <b-button
              icon-right="chevron-right"
              :disabled="next.disabled"
              @click.prevent="next.action"
              class="has-text-rigth next-button"
            >
              Siguiente
            </b-button>
          </div>
        </template>
      </b-steps>
    </section> -->
  </div>
</template>

<script>
import { NAPSI as questions } from "../../../data/questions";
import { NAPSI as answers } from "../../../data/answers";
import NapsiDefaultForm from "../../../components/Forms/NapsiDefaultForm.vue";

export default {
  components: { NapsiDefaultForm },
  data() {
    return {
      questions: questions,
      answers: answers,
      activeStep: 0,
      showSocial: true,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: true,
      handNailsComplete: false,
      feetNailsComplete: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
      handNails: 0,
      feetNails: 0,
      handNailsAnswers: [],
      feetNailsAnswers: [],
      answersConsolidate: [],
      temporal: {},
      queryMode: false,
      result: "",
      handQueryAnsers: [],
      feetQueryAnsers: [],
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "NapsiList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.temporal = this.$route.params.temporalData;
    }
  },
  computed: {
    score() {
      return this.handNails + this.feetNails;
    },
    isIncompleted() {
      return this.score === 0;
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    setInfo(moduleData) {
      switch (moduleData.module) {
        case "hands":
          this.handNails = moduleData.score;
          this.handNailsAnswers = moduleData.answers;
          this.temporal.hands = moduleData.answers;
          this.handNailsComplete = moduleData.isComplete;
          break;
        case "feet":
          this.feetNails = moduleData.score;
          this.feetNailsAnswers = moduleData.answers;
          this.temporal.feet = moduleData.answers;
          this.feetNailsComplete = moduleData.isComplete;
          break;
      }

      this.saveTermporalSulrvey();
    },
    cleanForms() {
      this.handNailsComplete = false;
      this.feetNailsComplete = false;
      this.handNailsAnswers = [];
      this.feetNailsAnswers = [];
      this.answersConsolidate = [];
      this.activeStep = 0;
      this.$global.$emit("clearForm");
    },
    clear() {
      this.cleanForms();
      this.clearTemporalSurveys();
      this.showUndoDialog("Se limpiaron los datos de  la encuesta NAPSI.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        this.setAnswers();
        let data = {
          surveyCode: "NAPSI",
          result: String(this.score),
          appreciation: "",
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.answersConsolidate,
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.cleanForms();
        this.clearTemporalSurveys();
        this.changeLoaderStatus(false);
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      this.handNailsAnswers.forEach((element) => {
        this.answersConsolidate.push(element);
      });

      this.feetNailsAnswers.forEach((element) => {
        this.answersConsolidate.push(element);
      });
    },
    validate() {
      this.save();
      //  if (!this.isIncompleted) {
      //   this.save();
      // } else {
      //   this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      // }
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "NAPSI",
          route: "Napsi",
        },
        survey: {
          hands: this.temporal.hands,
          feet: this.temporal.feet,
        },
      });
    },
    setEvaluationAnswers(data) {
      this.result = data.result;
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code.includes("MANOS")) {
          this.handQueryAnsers.push(survey);
        }

        if (survey.question.code.includes("PIES")) {
          this.feetQueryAnsers.push(survey);
        }
      });

      this.$global.$emit("setQueryAnswers");
    },
  },
};
</script>
