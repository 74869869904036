<template>
  <div class="is-centered">
    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th></th>

            <th v-for="(item, index) in 10" :key="index">
              <b-tag type="is-dark" rounded class="ml-5">{{ item }}</b-tag>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MATRIX</td>
            <td></td>

            <td>
              <b-field>
                <b-select
                  v-model="finger1Matrix"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger1MatrixAnswers"
                    :key="index"
                    :value="item[1]"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger2Matrix"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger2MatrixAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger3Matrix"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger3MatrixAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger4Matrix"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger4MatrixAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger5Matrix"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger5MatrixAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger6Matrix"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger6MatrixAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger7Matrix"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger7MatrixAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger8Matrix"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger8MatrixAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger9Matrix"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger9MatrixAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger10Matrix"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger10MatrixAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
          </tr>
          <tr>
            <td>LECHO</td>
            <td></td>

            <td>
              <b-field>
                <b-select
                  v-model="finger1Bed"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger1BedAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger2Bed"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger2BedAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger3Bed"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger3BedAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger4Bed"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger4BedAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger5Bed"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger5BedAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger6Bed"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger6BedAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger7Bed"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger7BedAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger8Bed"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger8BedAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger9Bed"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger9BedAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
            <td>
              <b-field>
                <b-select
                  v-model="finger10Bed"
                  @input="emitData"
                  :disabled="queryMode"
                  rounded
                >
                  <option
                    v-for="(item, index) in finger10BedAnswers"
                    :key="index"
                    :value="index"
                    >{{ index }}</option
                  >
                </b-select>
              </b-field>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    module: {
      type: String,
      default: "Dia1",
    },
    question: {
      type: Object,
      default: null,
    },
    answers: {
      type: Object,
      default: null,
    },
    temporalData: {
      type: Array,
      default: null,
    },
    queryMode: {
      type: Boolean,
      default: false,
    },
    queryAnswers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      finger1Matrix: this.answers.MATRIX.NAPSI_MANOS_DEDO1_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO1_MATRIX
            .NAPSI_MANOS_DEDO1_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO1_MATRIX
            .NAPSI_PIES_DEDO1_MATRIX_R1,
      finger2Matrix: this.answers.MATRIX.NAPSI_MANOS_DEDO2_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO2_MATRIX
            .NAPSI_MANOS_DEDO2_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO2_MATRIX
            .NAPSI_PIES_DEDO2_MATRIX_R1,
      finger3Matrix: this.answers.MATRIX.NAPSI_MANOS_DEDO3_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO3_MATRIX
            .NAPSI_MANOS_DEDO3_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO3_MATRIX
            .NAPSI_PIES_DEDO3_MATRIX_R1,
      finger4Matrix: this.answers.MATRIX.NAPSI_MANOS_DEDO4_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO4_MATRIX
            .NAPSI_MANOS_DEDO4_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO4_MATRIX
            .NAPSI_PIES_DEDO4_MATRIX_R1,
      finger5Matrix: this.answers.MATRIX.NAPSI_MANOS_DEDO5_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO5_MATRIX
            .NAPSI_MANOS_DEDO5_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO5_MATRIX
            .NAPSI_PIES_DEDO5_MATRIX_R1,
      finger6Matrix: this.answers.MATRIX.NAPSI_MANOS_DEDO6_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO6_MATRIX
            .NAPSI_MANOS_DEDO6_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO6_MATRIX
            .NAPSI_PIES_DEDO6_MATRIX_R1,
      finger7Matrix: this.answers.MATRIX.NAPSI_MANOS_DEDO7_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO7_MATRIX
            .NAPSI_MANOS_DEDO7_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO7_MATRIX
            .NAPSI_PIES_DEDO7_MATRIX_R1,
      finger8Matrix: this.answers.MATRIX.NAPSI_MANOS_DEDO8_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO8_MATRIX
            .NAPSI_MANOS_DEDO8_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO8_MATRIX
            .NAPSI_PIES_DEDO8_MATRIX_R1,
      finger9Matrix: this.answers.MATRIX.NAPSI_MANOS_DEDO9_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO9_MATRIX
            .NAPSI_MANOS_DEDO9_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO9_MATRIX
            .NAPSI_PIES_DEDO9_MATRIX_R1,
      finger10Matrix: this.answers.MATRIX.NAPSI_MANOS_DEDO10_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO10_MATRIX
            .NAPSI_MANOS_DEDO10_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO10_MATRIX
            .NAPSI_PIES_DEDO10_MATRIX_R1,
      finger1Bed: this.answers.LECHO.NAPSI_MANOS_DEDO1_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO1_LECHO.NAPSI_MANOS_DEDO1_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO1_MATRIX
            .NAPSI_PIES_DEDO1_MATRIX_R1,
      finger2Bed: this.answers.LECHO.NAPSI_MANOS_DEDO2_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO2_LECHO.NAPSI_MANOS_DEDO2_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO2_MATRIX
            .NAPSI_PIES_DEDO2_MATRIX_R1,
      finger3Bed: this.answers.LECHO.NAPSI_MANOS_DEDO3_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO3_LECHO.NAPSI_MANOS_DEDO3_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO3_MATRIX
            .NAPSI_PIES_DEDO3_MATRIX_R1,
      finger4Bed: this.answers.LECHO.NAPSI_MANOS_DEDO4_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO4_LECHO.NAPSI_MANOS_DEDO4_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO4_MATRIX
            .NAPSI_PIES_DEDO4_MATRIX_R1,
      finger5Bed: this.answers.LECHO.NAPSI_MANOS_DEDO5_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO5_LECHO.NAPSI_MANOS_DEDO5_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO5_MATRIX
            .NAPSI_PIES_DEDO5_MATRIX_R1,
      finger6Bed: this.answers.LECHO.NAPSI_MANOS_DEDO6_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO6_LECHO.NAPSI_MANOS_DEDO6_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO6_MATRIX
            .NAPSI_PIES_DEDO6_MATRIX_R1,
      finger7Bed: this.answers.LECHO.NAPSI_MANOS_DEDO7_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO7_LECHO.NAPSI_MANOS_DEDO7_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO7_MATRIX
            .NAPSI_PIES_DEDO7_MATRIX_R1,
      finger8Bed: this.answers.LECHO.NAPSI_MANOS_DEDO8_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO8_LECHO.NAPSI_MANOS_DEDO8_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO8_MATRIX
            .NAPSI_PIES_DEDO8_MATRIX_R1,
      finger9Bed: this.answers.LECHO.NAPSI_MANOS_DEDO9_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO9_LECHO.NAPSI_MANOS_DEDO9_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO9_MATRIX
            .NAPSI_PIES_DEDO9_MATRIX_R1,
      finger10Bed: this.answers.LECHO.NAPSI_MANOS_DEDO10_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO10_LECHO
            .NAPSI_MANOS_DEDO10_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO10_MATRIX
            .NAPSI_PIES_DEDO10_MATRIX_R1,

      questionMatrixKeys: [],
      questionLechoKeys: [],
      finger1MatrixAnswers: [],
      finger2MatrixAnswers: [],
      finger3MatrixAnswers: [],
      finger4MatrixAnswers: [],
      finger5MatrixAnswers: [],
      finger6MatrixAnswers: [],
      finger7MatrixAnswers: [],
      finger8MatrixAnswers: [],
      finger9MatrixAnswers: [],
      finger10MatrixAnswers: [],
      finger1BedAnswers: [],
      finger2BedAnswers: [],
      finger3BedAnswers: [],
      finger4BedAnswers: [],
      finger5BedAnswers: [],
      finger6BedAnswers: [],
      finger7BedAnswers: [],
      finger8BedAnswers: [],
      finger9BedAnswers: [],
      finger10BedAnswers: [],
      restoreData: {},
    };
  },
  created() {
    this.setKeys();

    this.$global.$on("setQueryAnswers", () => {
      this.setQueryFormAnswers();
    });

    if (this.temporalData && this.temporalData.length > 0) {
      this.finger1Matrix = this.temporalData[0].value;
      this.finger2Matrix = this.temporalData[1].value;
      this.finger3Matrix = this.temporalData[2].value;
      this.finger4Matrix = this.temporalData[3].value;
      this.finger5Matrix = this.temporalData[4].value;
      this.finger6Matrix = this.temporalData[5].value;
      this.finger7Matrix = this.temporalData[6].value;
      this.finger8Matrix = this.temporalData[7].value;
      this.finger9Matrix = this.temporalData[8].value;
      this.finger10Matrix = this.temporalData[9].value;
      this.finger1Bed = this.temporalData[10].value;
      this.finger2Bed = this.temporalData[11].value;
      this.finger3Bed = this.temporalData[12].value;
      this.finger4Bed = this.temporalData[13].value;
      this.finger5Bed = this.temporalData[14].value;
      this.finger6Bed = this.temporalData[15].value;
      this.finger7Bed = this.temporalData[16].value;
      this.finger8Bed = this.temporalData[17].value;
      this.finger9Bed = this.temporalData[18].value;
      this.finger10Bed = this.temporalData[19].value;

      this.emitData();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });

    this.$global.$on("clearForm", () => {
      this.clear();
    });
  },
  computed: {
    score() {
      return (
        parseInt(this.finger1Matrix || 0) +
        parseInt(this.finger2Matrix || 0) +
        parseInt(this.finger3Matrix || 0) +
        parseInt(this.finger4Matrix || 0) +
        parseInt(this.finger5Matrix || 0) +
        parseInt(this.finger6Matrix || 0) +
        parseInt(this.finger7Matrix || 0) +
        parseInt(this.finger8Matrix || 0) +
        parseInt(this.finger9Matrix || 0) +
        parseInt(this.finger10Matrix || 0) +
        parseInt(this.finger1Bed || 0) +
        parseInt(this.finger2Bed || 0) +
        parseInt(this.finger3Bed || 0) +
        parseInt(this.finger4Bed || 0) +
        parseInt(this.finger5Bed || 0) +
        parseInt(this.finger6Bed || 0) +
        parseInt(this.finger7Bed || 0) +
        parseInt(this.finger8Bed || 0) +
        parseInt(this.finger9Bed || 0) +
        parseInt(this.finger10Bed || 0)
      );
    },
    isIncompleted() {
      return (
        this.finger1Matrix === "" ||
        this.finger2Matrix === "" ||
        this.finger3Matrix === "" ||
        this.finger4Matrix === "" ||
        this.finger5Matrix === "" ||
        this.finger6Matrix === "" ||
        this.finger7Matrix === "" ||
        this.finger8Matrix === "" ||
        this.finger9Matrix === "" ||
        this.finger10Matrix === "" ||
        this.finger1Bed === "" ||
        this.finger2Bed === "" ||
        this.finger3Bed === "" ||
        this.finger4Bed === "" ||
        this.finger5Bed === "" ||
        this.finger6Bed === "" ||
        this.finger7Bed === "" ||
        this.finger8Bed === "" ||
        this.finger9Bed === "" ||
        this.finger10Bed === ""
      );
    },
  },
  methods: {
    setKeys() {
      this.questionMatrixKeys = Object.keys(this.question.MATRIX);
      this.questionBedKeys = Object.keys(this.question.LECHO);

      this.finger1MatrixAnswers = Object.entries(
        this.answers.MATRIX[this.questionMatrixKeys[0]]
      );
      this.finger2MatrixAnswers = Object.entries(
        this.answers.MATRIX[this.questionMatrixKeys[1]]
      );
      this.finger3MatrixAnswers = Object.entries(
        this.answers.MATRIX[this.questionMatrixKeys[2]]
      );
      this.finger4MatrixAnswers = Object.entries(
        this.answers.MATRIX[this.questionMatrixKeys[3]]
      );
      this.finger5MatrixAnswers = Object.entries(
        this.answers.MATRIX[this.questionMatrixKeys[4]]
      );
      this.finger6MatrixAnswers = Object.entries(
        this.answers.MATRIX[this.questionMatrixKeys[5]]
      );
      this.finger7MatrixAnswers = Object.entries(
        this.answers.MATRIX[this.questionMatrixKeys[6]]
      );
      this.finger8MatrixAnswers = Object.entries(
        this.answers.MATRIX[this.questionMatrixKeys[7]]
      );
      this.finger9MatrixAnswers = Object.entries(
        this.answers.MATRIX[this.questionMatrixKeys[8]]
      );
      this.finger10MatrixAnswers = Object.entries(
        this.answers.MATRIX[this.questionMatrixKeys[9]]
      );

      this.finger1BedAnswers = Object.entries(
        this.answers.LECHO[this.questionBedKeys[0]]
      );
      this.finger2BedAnswers = Object.entries(
        this.answers.LECHO[this.questionBedKeys[1]]
      );
      this.finger3BedAnswers = Object.entries(
        this.answers.LECHO[this.questionBedKeys[2]]
      );
      this.finger4BedAnswers = Object.entries(
        this.answers.LECHO[this.questionBedKeys[3]]
      );
      this.finger5BedAnswers = Object.entries(
        this.answers.LECHO[this.questionBedKeys[4]]
      );
      this.finger6BedAnswers = Object.entries(
        this.answers.LECHO[this.questionBedKeys[5]]
      );
      this.finger7BedAnswers = Object.entries(
        this.answers.LECHO[this.questionBedKeys[6]]
      );
      this.finger8BedAnswers = Object.entries(
        this.answers.LECHO[this.questionBedKeys[7]]
      );
      this.finger9BedAnswers = Object.entries(
        this.answers.LECHO[this.questionBedKeys[8]]
      );
      this.finger10BedAnswers = Object.entries(
        this.answers.LECHO[this.questionBedKeys[9]]
      );
    },
    clear() {
      this.restoreData.finger1Matrix = this.finger1Matrix;
      this.restoreData.finger2Matrix = this.finger2Matrix;
      this.restoreData.finger3Matrix = this.finger3Matrix;
      this.restoreData.finger4Matrix = this.finger4Matrix;
      this.restoreData.finger5Matrix = this.finger5Matrix;
      this.restoreData.finger6Matrix = this.finger6Matrix;
      this.restoreData.finger7Matrix = this.finger7Matrix;
      this.restoreData.finger8Matrix = this.finger8Matrix;
      this.restoreData.finger9Matrix = this.finger9Matrix;
      this.restoreData.finger10Matrix = this.finger10Matrix;
      this.restoreData.finger1Bed = this.finger1Bed;
      this.restoreData.finger2Bed = this.finger2Bed;
      this.restoreData.finger3Bed = this.finger3Bed;
      this.restoreData.finger4Bed = this.finger4Bed;
      this.restoreData.finger5Bed = this.finger5Bed;
      this.restoreData.finger6Bed = this.finger6Bed;
      this.restoreData.finger7Bed = this.finger7Bed;
      this.restoreData.finger8Bed = this.finger8Bed;
      this.restoreData.finger9Bed = this.finger9Bed;
      this.restoreData.finger10Bed = this.finger10Bed;

      this.finger1Matrix = this.answers.MATRIX.NAPSI_MANOS_DEDO1_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO1_MATRIX
            .NAPSI_MANOS_DEDO1_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO1_MATRIX
            .NAPSI_PIES_DEDO1_MATRIX_R1;
      this.finger2Matrix = this.answers.MATRIX.NAPSI_MANOS_DEDO2_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO2_MATRIX
            .NAPSI_MANOS_DEDO2_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO2_MATRIX
            .NAPSI_PIES_DEDO2_MATRIX_R1;
      this.finger3Matrix = this.answers.MATRIX.NAPSI_MANOS_DEDO3_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO3_MATRIX
            .NAPSI_MANOS_DEDO3_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO3_MATRIX
            .NAPSI_PIES_DEDO3_MATRIX_R1;
      this.finger4Matrix = this.answers.MATRIX.NAPSI_MANOS_DEDO4_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO4_MATRIX
            .NAPSI_MANOS_DEDO4_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO4_MATRIX
            .NAPSI_PIES_DEDO4_MATRIX_R1;
      this.finger5Matrix = this.answers.MATRIX.NAPSI_MANOS_DEDO5_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO5_MATRIX
            .NAPSI_MANOS_DEDO5_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO5_MATRIX
            .NAPSI_PIES_DEDO5_MATRIX_R1;
      this.finger6Matrix = this.answers.MATRIX.NAPSI_MANOS_DEDO6_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO6_MATRIX
            .NAPSI_MANOS_DEDO6_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO6_MATRIX
            .NAPSI_PIES_DEDO6_MATRIX_R1;
      this.finger7Matrix = this.answers.MATRIX.NAPSI_MANOS_DEDO7_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO7_MATRIX
            .NAPSI_MANOS_DEDO7_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO7_MATRIX
            .NAPSI_PIES_DEDO7_MATRIX_R1;
      this.finger8Matrix = this.answers.MATRIX.NAPSI_MANOS_DEDO8_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO8_MATRIX
            .NAPSI_MANOS_DEDO8_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO8_MATRIX
            .NAPSI_PIES_DEDO8_MATRIX_R1;
      this.finger9Matrix = this.answers.MATRIX.NAPSI_MANOS_DEDO9_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO9_MATRIX
            .NAPSI_MANOS_DEDO9_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO9_MATRIX
            .NAPSI_PIES_DEDO9_MATRIX_R1;
      this.finger10Matrix = this.answers.MATRIX.NAPSI_MANOS_DEDO10_MATRIX
        ? this.answers.MATRIX.NAPSI_MANOS_DEDO10_MATRIX
            .NAPSI_MANOS_DEDO10_MATRIX_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO10_MATRIX
            .NAPSI_PIES_DEDO10_MATRIX_R1;
      this.finger1Bed = this.answers.LECHO.NAPSI_MANOS_DEDO1_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO1_LECHO.NAPSI_MANOS_DEDO1_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO1_MATRIX
            .NAPSI_PIES_DEDO1_MATRIX_R1;
      this.finger2Bed = this.answers.LECHO.NAPSI_MANOS_DEDO2_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO2_LECHO.NAPSI_MANOS_DEDO2_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO2_MATRIX
            .NAPSI_PIES_DEDO2_MATRIX_R1;
      this.finger3Bed = this.answers.LECHO.NAPSI_MANOS_DEDO3_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO3_LECHO.NAPSI_MANOS_DEDO3_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO3_MATRIX
            .NAPSI_PIES_DEDO3_MATRIX_R1;
      this.finger4Bed = this.answers.LECHO.NAPSI_MANOS_DEDO4_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO4_LECHO.NAPSI_MANOS_DEDO4_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO4_MATRIX
            .NAPSI_PIES_DEDO4_MATRIX_R1;
      this.finger5Bed = this.answers.LECHO.NAPSI_MANOS_DEDO5_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO5_LECHO.NAPSI_MANOS_DEDO5_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO5_MATRIX
            .NAPSI_PIES_DEDO5_MATRIX_R1;
      this.finger6Bed = this.answers.LECHO.NAPSI_MANOS_DEDO6_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO6_LECHO.NAPSI_MANOS_DEDO6_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO6_MATRIX
            .NAPSI_PIES_DEDO6_MATRIX_R1;
      this.finger7Bed = this.answers.LECHO.NAPSI_MANOS_DEDO7_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO7_LECHO.NAPSI_MANOS_DEDO7_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO7_MATRIX
            .NAPSI_PIES_DEDO7_MATRIX_R1;
      this.finger8Bed = this.answers.LECHO.NAPSI_MANOS_DEDO8_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO8_LECHO.NAPSI_MANOS_DEDO8_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO8_MATRIX
            .NAPSI_PIES_DEDO8_MATRIX_R1;
      this.finger9Bed = this.answers.LECHO.NAPSI_MANOS_DEDO9_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO9_LECHO.NAPSI_MANOS_DEDO9_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO9_MATRIX
            .NAPSI_PIES_DEDO9_MATRIX_R1;
      this.finger10Bed = this.answers.LECHO.NAPSI_MANOS_DEDO10_LECHO
        ? this.answers.LECHO.NAPSI_MANOS_DEDO10_LECHO
            .NAPSI_MANOS_DEDO10_LECHO_R1
        : this.answers.MATRIX.NAPSI_PIES_DEDO10_MATRIX
            .NAPSI_PIES_DEDO10_MATRIX_R1;
      this.emitData();
    },
    emitData() {
      this.$emit("formInfo", {
        score: this.score,
        module: this.module,
        isComplete: !this.isIncompleted,
        answers: this.setAnswers(),
      });
    },
    setAnswers() {
      let matrix1 = null;
      let matrix2 = null;
      let matrix3 = null;
      let matrix4 = null;
      let matrix5 = null;
      let matrix6 = null;
      let matrix7 = null;
      let matrix8 = null;
      let matrix9 = null;
      let matrix10 = null;
      let lecho1 = null;
      let lecho2 = null;
      let lecho3 = null;
      let lecho4 = null;
      let lecho5 = null;
      let lecho6 = null;
      let lecho7 = null;
      let lecho8 = null;
      let lecho9 = null;
      let lecho10 = null;

      this.finger1MatrixAnswers.forEach((element) => {
        if (element[1] == this.finger1Matrix) {
          matrix1 = {
            questionCode: this.questionMatrixKeys[0],
            answerCode: element[0],
            value: this.finger1Matrix,
          };
        }
      });

      this.finger2MatrixAnswers.forEach((element) => {
        if (element[1] == this.finger2Matrix) {
          matrix2 = {
            questionCode: this.questionMatrixKeys[1],
            answerCode: element[0],
            value: this.finger2Matrix,
          };
        }
      });

      this.finger3MatrixAnswers.forEach((element) => {
        if (element[1] == this.finger3Matrix) {
          matrix3 = {
            questionCode: this.questionMatrixKeys[2],
            answerCode: element[0],
            value: this.finger3Matrix,
          };
        }
      });

      this.finger4MatrixAnswers.forEach((element) => {
        if (element[1] == this.finger4Matrix) {
          matrix4 = {
            questionCode: this.questionMatrixKeys[3],
            answerCode: element[0],
            value: this.finger4Matrix,
          };
        }
      });

      this.finger5MatrixAnswers.forEach((element) => {
        if (element[1] == this.finger5Matrix) {
          matrix5 = {
            questionCode: this.questionMatrixKeys[4],
            answerCode: element[0],
            value: this.finger5Matrix,
          };
        }
      });

      this.finger6MatrixAnswers.forEach((element) => {
        if (element[1] == this.finger6Matrix) {
          matrix6 = {
            questionCode: this.questionMatrixKeys[5],
            answerCode: element[0],
            value: this.finger6Matrix,
          };
        }
      });

      this.finger7MatrixAnswers.forEach((element) => {
        if (element[1] == this.finger7Matrix) {
          matrix7 = {
            questionCode: this.questionMatrixKeys[6],
            answerCode: element[0],
            value: this.finger7Matrix,
          };
        }
      });

      this.finger8MatrixAnswers.forEach((element) => {
        if (element[1] == this.finger8Matrix) {
          matrix8 = {
            questionCode: this.questionMatrixKeys[7],
            answerCode: element[0],
            value: this.finger8Matrix,
          };
        }
      });

      this.finger9MatrixAnswers.forEach((element) => {
        if (element[1] == this.finger9Matrix) {
          matrix9 = {
            questionCode: this.questionMatrixKeys[8],
            answerCode: element[0],
            value: this.finger9Matrix,
          };
        }
      });

      this.finger10MatrixAnswers.forEach((element) => {
        if (element[1] == this.finger10Matrix) {
          matrix10 = {
            questionCode: this.questionMatrixKeys[9],
            answerCode: element[0],
            value: this.finger10Matrix,
          };
        }
      });

      this.finger1BedAnswers.forEach((element) => {
        if (element[1] == this.finger1Matrix) {
          lecho1 = {
            questionCode: this.questionBedKeys[0],
            answerCode: element[0],
            value: this.finger1Bed,
          };
        }
      });

      this.finger2BedAnswers.forEach((element) => {
        if (element[1] == this.finger2Matrix) {
          lecho2 = {
            questionCode: this.questionBedKeys[1],
            answerCode: element[0],
            value: this.finger2Bed,
          };
        }
      });

      this.finger3BedAnswers.forEach((element) => {
        if (element[1] == this.finger3Matrix) {
          lecho3 = {
            questionCode: this.questionBedKeys[2],
            answerCode: element[0],
            value: this.finger3Bed,
          };
        }
      });

      this.finger4BedAnswers.forEach((element) => {
        if (element[1] == this.finger4Matrix) {
          lecho4 = {
            questionCode: this.questionBedKeys[3],
            answerCode: element[0],
            value: this.finger4Bed,
          };
        }
      });

      this.finger5BedAnswers.forEach((element) => {
        if (element[1] == this.finger5Matrix) {
          lecho5 = {
            questionCode: this.questionBedKeys[4],
            answerCode: element[0],
            value: this.finger5Bed,
          };
        }
      });

      this.finger6BedAnswers.forEach((element) => {
        if (element[1] == this.finger6Matrix) {
          lecho6 = {
            questionCode: this.questionBedKeys[5],
            answerCode: element[0],
            value: this.finger6Bed,
          };
        }
      });

      this.finger7BedAnswers.forEach((element) => {
        if (element[1] == this.finger7Matrix) {
          lecho7 = {
            questionCode: this.questionBedKeys[6],
            answerCode: element[0],
            value: this.finger7Bed,
          };
        }
      });

      this.finger8BedAnswers.forEach((element) => {
        if (element[1] == this.finger8Matrix) {
          lecho8 = {
            questionCode: this.questionBedKeys[7],
            answerCode: element[0],
            value: this.finger8Bed,
          };
        }
      });

      this.finger9BedAnswers.forEach((element) => {
        if (element[1] == this.finger9Matrix) {
          lecho9 = {
            questionCode: this.questionBedKeys[8],
            answerCode: element[0],
            value: this.finger9Bed,
          };
        }
      });

      this.finger10BedAnswers.forEach((element) => {
        if (element[1] == this.finger10Matrix) {
          lecho10 = {
            questionCode: this.questionBedKeys[9],
            answerCode: element[0],
            value: this.finger10Bed,
          };
        }
      });

      return [
        matrix1,
        matrix2,
        matrix3,
        matrix4,
        matrix5,
        matrix6,
        matrix7,
        matrix8,
        matrix9,
        matrix10,
        lecho1,
        lecho2,
        lecho3,
        lecho4,
        lecho5,
        lecho6,
        lecho7,
        lecho8,
        lecho9,
        lecho10,
      ];
    },
    restoreForm() {
      this.finger1Matrix = this.restoreData.finger1Matrix;
      this.finger2Matrix = this.restoreData.finger2Matrix;
      this.finger3Matrix = this.restoreData.finger3Matrix;
      this.finger4Matrix = this.restoreData.finger4Matrix;
      this.finger5Matrix = this.restoreData.finger5Matrix;
      this.finger6Matrix = this.restoreData.finger6Matrix;
      this.finger7Matrix = this.restoreData.finger7Matrix;
      this.finger8Matrix = this.restoreData.finger8Matrix;
      this.finger9Matrix = this.restoreData.finger9Matrix;
      this.finger10Matrix = this.restoreData.finger10Matrix;
      this.finger1Bed = this.restoreData.finger1Bed;
      this.finger2Bed = this.restoreData.finger2Bed;
      this.finger3Bed = this.restoreData.finger3Bed;
      this.finger4Bed = this.restoreData.finger4Bed;
      this.finger5Bed = this.restoreData.finger5Bed;
      this.finger6Bed = this.restoreData.finger6Bed;
      this.finger7Bed = this.restoreData.finger7Bed;
      this.finger8Bed = this.restoreData.finger8Bed;
      this.finger9Bed = this.restoreData.finger9Bed;
      this.finger10Bed = this.restoreData.finger10Bed;
      this.emitData();
    },
    setQueryFormAnswers() {
      this.queryAnswers.forEach((survey) => {
        if (survey.question.code.includes("DEDO1_MATRIX")) {
          this.finger1Matrix = survey.value;
        }

        if (survey.question.code.includes("DEDO2_MATRIX")) {
          this.finger2Matrix = survey.value;
        }

        if (survey.question.code.includes("DEDO3_MATRIX")) {
          this.finger3Matrix = survey.value;
        }

        if (survey.question.code.includes("DEDO4_MATRIX")) {
          this.finger4Matrix = survey.value;
        }

        if (survey.question.code.includes("DEDO5_MATRIX")) {
          this.finger5Matrix = survey.value;
        }

        if (survey.question.code.includes("DEDO6_MATRIX")) {
          this.finger6Matrix = survey.value;
        }

        if (survey.question.code.includes("DEDO7_MATRIX")) {
          this.finger7Matrix = survey.value;
        }

        if (survey.question.code.includes("DEDO8_MATRIX")) {
          this.finger8Matrix = survey.value;
        }

        if (survey.question.code.includes("DEDO9_MATRIX")) {
          this.finger9Matrix = survey.value;
        }

        if (survey.question.code.includes("DEDO10_MATRIX")) {
          this.finger10Matrix = survey.value;
        }

        if (survey.question.code.includes("DEDO1_LECHO")) {
          this.finger1Bed = survey.value;
        }

        if (survey.question.code.includes("DEDO2_LECHO")) {
          this.finger2Bed = survey.value;
        }

        if (survey.question.code.includes("DEDO3_LECHO")) {
          this.finger3Bed = survey.value;
        }

        if (survey.question.code.includes("DEDO4_LECHO")) {
          this.finger4Bed = survey.value;
        }

        if (survey.question.code.includes("DEDO5_LECHO")) {
          this.finger5Bed = survey.value;
        }

        if (survey.question.code.includes("DEDO6_LECHO")) {
          this.finger6Bed = survey.value;
        }

        if (survey.question.code.includes("DEDO7_LECHO")) {
          this.finger7Bed = survey.value;
        }

        if (survey.question.code.includes("DEDO8_LECHO")) {
          this.finger8Bed = survey.value;
        }

        if (survey.question.code.includes("DEDO9_LECHO")) {
          this.finger9Bed = survey.value;
        }

        if (survey.question.code.includes("DEDO10_LECHO")) {
          this.finger10Bed = survey.value;
        }
      });
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  background-color: transparent;
}
.table-container {
  overflow: auto;
}
</style>
